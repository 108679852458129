.AboutPic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../images/service11.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
.Partnership{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Partnership-caro{
    width: 100%;
    height: auto;
    /* background-color:#156C5F; */
    clip-path: polygon(100% 0, 100% 90%, 50% 76%, 0 90%, 0 0);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
   background: url('../../images/about1.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   
}
.partnership-data{
 width: 90%;
 height: auto;
 /* background-color: red; */
 display: flex;
 flex-wrap: wrap;
 justify-content: space-evenly;
 align-items: center;
 gap: 30px;
 padding-top: 100px;
 /* background-color: #F5F5F5; */
}
.partnership-card{
  width: 35%;
  /* gap: 40px; */
  padding: 5px;
  height: auto;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-bottom:4px solid #156C5F;
  /* opacity: 0.5; */
  padding-bottom: 20px;
  min-width: 300px;
}
.partnership-card img{
    width: 60%;
    height: auto;
}
.partnership-card h3{
 color: #EE8961;
}
.partnership-card span{
    font-weight: 400;
    font-size: 15px;
    
}