.CrisisManagement-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: url('https://www.swiftelearningservices.com/wp-content/uploads/2020/06/CRISIS-MANAGEMENT.jpg'); */
    background-image: url('../../images/jl9.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-color: blue;
}