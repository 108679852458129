.Mentoring-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('https://img.freepik.com/free-photo/female-nurse-talking-with-old-woman-with-alzheimer-nursing-home_482257-20723.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}