.CQCChallenges-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: url('https://static.independent.co.uk/2023/04/16/11/16114204-2e0a1d5b-7a46-4a2b-b7de-facdcfbeffce.jpg'); */
    background-image: url('../../images/jl7.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;

}