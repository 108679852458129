
.AboutPic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: url('../../images/happyplp.jpg'); */
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
.AboutPic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    position: absolute;
    position: sticky;
    background-attachment: fixed;    
}

.about-texts{
    width: 70%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    /* background-color: violet; */
}
.about-texts h1{
    
    font-size: 60px;
    font-family: "Poppins", sans-serif;
 font-weight: 500;
 font-style: normal;  
}
.about-texts p{
    
    color: white;
    font-size: 24px;
    font-family: "Poppins", sans-serif;
 font-weight: 500;
 font-style: normal; 

}
.about-opacity{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    top: 0;
    
}



@media (max-width:650px){
    .about-texts p{
    
    color: white;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
 font-weight: 500;
 font-style: normal; 

}
.about-texts h1{
    
    color: EE8961;
    font-size: 40px;
    font-family: "Poppins", sans-serif;
 font-weight: 500;
 font-style: normal;  
}
.about-texts{
    width:90%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    /* background-color: violet; */
}
}