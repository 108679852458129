.Consultancy{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}
.Consultancy-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../images/care.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}

.Consultancy-data{
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal; 
    margin: auto; 
    margin-top: 20px;

    /* background-color:#EE8961 ;/ */
}
.Consultancy-data :nth-child(3){
    color: #7469B6;
    font-size: 27px;
}
.Consultancy-data h4{
    color: #AD88C6;
   
    font-size: 24px;
    /* font-family: monospace; */
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    
    font-weight: 500;
}
.Consultancy-data h1{
    color: #AD88C6;
    font-size: 28px;
}
.Consultancy-data p{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    /* margin-top: 20px; */
    color: rgb(109, 108, 108);
}

