.OurTeam{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Ourteam-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../images/about1.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
.ourteam-main{
    width: 90%;
    height: auto;
    /* background-color: yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 150px;
    gap: 30px;
    margin-bottom: 50px;
}
.ourteam-members{
    width: 350px;
    height: 350px;
    /* background-color: yellowgreen; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
}
.ourteam-members:hover{
  transform: scale(1.1);
  transition: calc(2s);
}
.ourteam-members img{
    width: 100%;
    height: 100%;
}
.member-data{
 position: absolute;
 width: 80%;
 height: 170px;

 background-color: white;
 top: 80%;
 border-radius: 20px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-evenly;
 border-bottom:2px solid #156C5F;
 padding: 10px;
 /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}
.member-data :nth-child(1){
    font-size: 25px;
    font-weight: 700;
    color: #EE8961;
}
.member-data :nth-child(2){
    font-size: 15px;
    font-weight: 500;
}
.member-data :nth-child(3){
    font-size: 15px;
    text-align: center;
}



@media (max-width:400px){
.ourteam-main{
    width: 90%;
    height: auto;
    /* background-color: yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
    gap: 30px;
    margin-bottom: 50px;
}
}