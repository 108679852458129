.case3-plan{
    width: 77%;
    height:700px;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    gap: 30px;
    z-index: 100;
}
.case3-plan span{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal; 
    font-size: 25px;
    color: #AD88C6;
    /* margin-bottom: 100px; */
}
.button-txt{
    color: #7469B6 !important;
}


@media only screen and (min-width: 800px) and (max-width: 1024px) {
     
    .case-heading{
        width: 87%;
        height: 100px;
      /* background-color: turquoise; */
      margin-left: 0%;
      
    }
    .case-heading :nth-child(1){
      font-size:15px ;

    }
    .case-heading :nth-child(2){
      font-size:23px ;
    }
    .case-issue{
        width: 43%;
        height: 330px;
        font-size: 13px;
        padding: 20px;
    }
    .case-help{
        width: 37%;
        height: 330px;
        font-size: 13px;
        padding: 20px;
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
    .case-heading{
        width: 97%;
        height: 100px;
      /* background-color: turquoise; */
      margin-left: 0%;
      
    }
    .case-heading :nth-child(1){
      font-size:15px ;

    }
    .case-heading :nth-child(2){
      font-size:23px ;
    }
    .cases-div{
        width: 100%;
        height: 450px;
        /* background-color: yellowgreen; */
    }
    .case-issue{
        width: 49%;
        height: 330px;
        font-size: 12px;
        padding: 20px;
    }
    .case-help{
        width: 45%;
        height: 330px;
        font-size: 13px;
        padding: 20px;
    }
    .case3-plan{
        width: 93%;
        height:650px;
        /* background-color: aquamarine; */
        
    }
    .case3-plan span{
     font-size: 20px;
    }
    .button-txt{
        font-size: 14px;
    }
    .case-overall{
        width: 93%;
        height: 200px;
        /* background-color: #156C5F; */
        padding: 20px;
        border-radius: 10px;
        font-size: 14px;
    }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
    .case-heading{
        width: 96%;
        height: 100px;
      /* background-color: turquoise; */
      margin-left: 0%;
      
    }
    .case-heading :nth-child(1){
      font-size:13px ;

    }
    .case-heading :nth-child(2){
      font-size:18px ;
    }
    .cases-div{
        width: 100%;
        height: 450px;
        /* background-color: yellowgreen; */
    }
    .case-issue{
        width: 49%;
        height: 430px;
        font-size: 12px;
        padding: 20px;
    }
    .case-help{
        width: 45%;
        height: 430px;
        font-size: 13px;
        padding: 20px;
    }
    .case3-plan{
        width: 93%;
        height:650px;
        /* background-color: aquamarine; */
        
    }
    .case3-plan span{
     font-size: 17px;
    }
    .button-txt{
        font-size: 12px;
    }
    .case-overall{
        width: 98%;
        height: 200px;
        /* background-color: #156C5F; */
        padding: 20px;
        border-radius: 10px;
        font-size: 12px;
    } 
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
    .case-heading{
        width: 96%;
        height: 100px;
      /* background-color: turquoise; */
      margin-left: 0%;
      
    }
    .case-heading :nth-child(1){
      font-size:11px ;

    }
    .case-heading :nth-child(2){
      font-size:15px ;
    }
    .cases-div{
        width: 100%;
        height: 450px;
        /* background-color: yellowgreen; */
    }
    .case-issue{
        width: 51%;
        height: 400px;
        font-size: 10px;
        padding-top: 30px !important;
        padding: 10px;
        display:flex ;
        align-items: center;
        justify-content: center;
        /* color: red; */
    }
    .case-help{
        width: 48%;
        height: 400px;
        font-size: 10px;
        padding: 10px;
    }
    .case3-plan{
        width: 95%;
        height:auto !important;
        /* background-color: aquamarine; */
        
    }
    .case3-plan span{
     font-size: 14px;
    }
    .button-txt{
        font-size: 10px;
    }
    .accordion-bgclr{
        background-color: white;
       font-size: 11px;   
    }

    .case-overall{
        width: 98%;
        height: 200px;
        /* background-color: #156C5F; */
        padding: 20px;
        border-radius: 10px;
        font-size: 12px;
    } 
}
@media only screen and (min-width: 100px) and (max-width: 300px) {
    .case-heading{
        width: 96%;
        height: 100px;
      /* background-color: turquoise; */
      margin-left: 0%;
      
    }
    .case-heading :nth-child(1){
      font-size:11px ;

    }
    .case-heading :nth-child(2){
      font-size:15px ;
    }
    .cases-div{
        width: 100%;
        height: 450px;
        /* background-color: yellowgreen; */
    }
    .case-issue{
        width: 51%;
        height: 400px;
        font-size: 10px;
        padding-top: 30px !important;
        padding: 10px;
        display:flex ;
        align-items: center;
        justify-content: center;
        /* color: red; */
    }
    .case-help{
        width: 48%;
        height: 400px;
        font-size: 10px;
        padding: 10px;
    }
    .case3-plan{
        width: 95%;
        height:650px;
        /* background-color: aquamarine; */
        
    }
    .case3-plan span{
     font-size: 14px;
    }
    .button-txt{
        font-size: 10px;
    }
    .case-overall{
        width: 98%;
        height: 200px;
        /* background-color: #156C5F; */
        padding: 20px;
        border-radius: 10px;
        font-size: 12px;
    } 
}

/* case3......................................... */