.ourhome{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.ourhome-main {
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image:url('./ourhome.jpg');
    background-attachment: fixed;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    margin-bottom: 50px;
    background-color: #ee8961;
    text-align: center;
  }

  .ourhome-main p{
    font-size: 24px;
    font-weight: 200;
  }

  .ourhome-desc{
    background-color: #AD88C6;
    width: 100%;
    height: 250px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 200;
    color: white;
    margin-top: -50px;
  }

  .ourhome-profile{
    background-color: white;
    width: 100%;
    height: 550px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 30px;
    color: #AD88C6;
  }
  .ourhome-profile h2{
font-weight: 100;
  }

  .ourhome-ppls{
    width: 100%;
    height: auto;
display: flex;
align-items: center;
justify-content: center;
gap: 50px;
/* background-color: yellow; */
  }

  .ourhome-card{
    width: 250px;
    height: 300px;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
color: black;
  }

  .ourhome-card img{
     width: 100%;
     height: 80%;
     object-fit: cover;
     background-size: contain;

  }
  .ourhome-card span{
  font-size: 10px   ;
  }

  .ourhome-drop{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .ourhome-drop-desc{
    width: 40%;
    height: 400px;
    /* background-color: yellowgreen; */
    padding: 20px;
    color: #000;
  }

  .ourhome-care-menu-one1{
    width: 50%;
    height: auto;
    /* padding:  20px 0 20px 0; */
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #AD88C6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    /* margin-bottom: 30px; */
    /* margin-left: 20px; */
  }

  .ourhome-services{
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 40px;
   /* background-color: #ee8961; */
   margin-top: 100px;
   margin-bottom: 100px;
  }
  .ourhome-services-card{
   width: 15%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   /* background-color: #617dee; */
  }

  .ourhome-gallery{
    width: 90%;
    height: auto;
    /* background-color: #AD88C6; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
padding: 20px;

  }

   .ourhome-gallery span{
  font-size: 40px;
  width: 30%;
  /* background-color: yellow; */
  }

  .image-gallery{
    height: auto;
    /* background-color: red; */
    width: 50%;
  }
  /* Increase the size of the thumbnails */
.image-gallery-thumbnails .image-gallery-thumbnail {
  width: 100px; /* Adjust the width as needed */
  height: 70px; 
  padding-left: 1px;/* Adjust the height as needed */
}

/* Ensure the thumbnail images fit within the new dimensions */
.image-gallery-thumbnails .image-gallery-thumbnail img {
  object-fit: cover;
  width: 90px;
  height: 60px;
}

  @media (max-width:1024px) {

    .ourhome-profile{
        background-color: white;
        width: 100%;
        height: 550px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 30px;
        color: #AD88C6;
      }
      .ourhome-profile h2{
    font-weight: 100;
      }
    
      .ourhome-ppls{
        width: 100%;
        height: auto;
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 50px;
    /* background-color: yellow; */
      }
    
      .ourhome-card{
        width: 250px;
        height: 300px;
        /* background-color: violet; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    color: black;
      }
    
      .ourhome-card img{
         width: 100%;
         height: 80%;
         object-fit: cover;
         background-size: contain;
    
      }
      .ourhome-card span{
      font-size: 10px   ;
      }
    
      .ourhome-drop{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .ourhome-drop-desc{
        width: 40%;
        height: 400px;
        /* background-color: yellowgreen; */
        padding: 20px;
        color: #000;
      }
    
      .ourhome-care-menu-one1{
        width: 50%;
        height: auto;
        /* padding:  20px 0 20px 0; */
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #AD88C6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        /* margin-bottom: 30px; */
        /* margin-left: 20px; */
      }
    
      .ourhome-services{
       width: 100%;
       height: auto;
       display: flex;
       align-items: center;
       justify-content: center;
       gap: 40px;
       /* background-color: #ee8961; */
       margin-top: 130px;
       margin-bottom: 100px;
      }
      .ourhome-services-card{
       width: 15%;
       height: auto;
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       /* background-color: #617dee; */
      }
    
      .ourhome-gallery{
        width: 90%;
        height: auto;
        /* background-color: #AD88C6; */
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    padding: 20px;
    
      }
    
       .ourhome-gallery span{
      font-size: 40px;
      width: 40%;
      }
    
      .image-gallery{
        height: auto;
        /* background-color: red; */
        width: 50%;
      }
    

  }
  @media (max-width:800px) {

    .ourhome-profile{
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: 900px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 30px;
        padding: 10px;
        color: #AD88C6;
        
      }
    .ourhome-ppls{
        width: 100%;
        height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    /* background-color: yellow; */
      }

      .ourhome-drop{
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        /* background-color: wheat; */
        gap: 30px;
      }
      .ourhome-drop-desc{
        width: 90%;
        height: 300px;
        /* background-color: yellowgreen; */
        padding: 0px;
        color: #000;
      }
    
      .ourhome-care-menu-one1{
        width: 95%;
        height: auto;
        /* padding:  20px 0 20px 0; */
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #AD88C6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        /* margin-bottom: 30px; */
        /* margin-left: 20px; */
      }
      .ourhome-services{
        width: 100%;
        height: 400px;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-evenly;
        gap: 0px;
        /* background-color: #ee8961; */
        margin-top: 40px;
        margin-bottom: 20px;
       }
       .ourhome-services-card{
        width: 35%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        /* background-color: #617dee; */
       }
       .ourhome-gallery{
        width: 95%;
        height: auto;
        /* background-color: #AD88C6;    */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
    padding: 10px;
    
      }
    
       .ourhome-gallery span{
      font-size: 30px;
      }
    
      .image-gallery{
        height: auto;
        /* background-color: red; */
        width: 100%;
      }

  }

  @media (max-width: 578px) {
    .ourhome-profile{
        background-color: white;
        width: 100%;
        height: 1000px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 30px;
        padding: 10px;
        color: #AD88C6;
      }
    .ourhome-ppls{
        width: 100%;
        height: 1000px;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background-color: rgb(0, 38, 255); */
      }

      .ourhome-drop{
        width: 100%;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
      .ourhome-drop-desc{
        width: 90%;
        height: 400px;
        /* background-color: yellowgreen; */
        padding: 20px;
        color: #000;
      }
    
      .ourhome-care-menu-one1{
        width: 95%;
        height: auto;
        /* padding:  20px 0 20px 0; */
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #AD88C6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        /* margin-bottom: 30px; */
        /* margin-left: 20px; */
      }
      .ourhome-services{
        width: 100%;
        height: 400px;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-evenly;
        gap: 0px;
        /* background-color: #ee8961; */
        margin-top: 10px;
        margin-bottom: 20px;
       }
       .ourhome-services-card{
        width: 35%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        /* background-color: #617dee; */
       }
       .ourhome-gallery{
        width: 95%;
        height: auto;
        /* background-color: #AD88C6;    */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
    padding: 10px;
    
      }
    
       .ourhome-gallery span{
      font-size: 20px;
      width: 90%;
      text-align: center;
      }
    
      .image-gallery{
        height: auto;
        /* background-color: red; */
        width: 100%;
      }
    
  }