
.PersonCentered-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../images/jl10.webp');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}