.ourhome-main2 {
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image:url('../images/home2.jpg');
    background-attachment: fixed;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    margin-bottom: 50px;
    background-color: #ee8961;
    text-align: center;
  }