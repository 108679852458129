.CrisisManagement-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('https://plus.unsplash.com/premium_photo-1661414415246-3e502e2fb241?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') ;
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-color: blue;
}
.consult-bgl-pic{
     width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('https://plus.unsplash.com/premium_photo-1661414415246-3e502e2fb241?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') ;
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-color: blue; 
}
@media (max-width:578px) {
    .Consultancy-data{
        width: 90%;
    }
}