.testmain-pic{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../images/happyplp.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}

.testmain{
    width: 100%;
    height: auto;
    /* background-color: #EE8961; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial-caro{
    width: 100%;
    height: 100%;
    /* background-color:#156C5F; */
    /* clip-path: polygon(100% 0, 100% 90%, 50% 76%, 0 90%, 0 0); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
}
.texts{
  width: 33%;
  height: 330px;
  /* background-color: rgb(129, 85, 140); */
  text-align: start;
  margin-left: 8%;
  font-family: "Poppins", sans-serif;
      /* font-weight: 500; */
      font-style: normal; 
      text-decoration: none;
      display: flex;
      flex-direction: column;
      /* justify-content: space-evenly; */
}
.texts h1{
    color: #EE8961;
}
.texts p{
    color: white;
    font-size: 14px;
    /* font-weight: 500; */
}
.texts button{
  width: 100px;
  height: 40px;
  background-color: #EE8961;
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  font-style: normal; 
  text-decoration: none;
  margin-top: 20px;
}

.testimonial-data {
    width: 90%;
    height: auto;
    justify-content: space-evenly;
    /* align-items: center; */
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    /* background-color: yellowgreen; */
    gap: 20px;
    padding-top: 100px;
}

.testimonial-data-items {
    width: 40%; 
    height: auto; 
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    background-color: white;
    text-align: justify; 
    border-bottom: 5px solid #7469B6; 
    font-family: "Poppins", sans-serif;
    /* font-weight: 500; */
    font-style: normal;
    border-radius: 10px; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    min-width: 320px;
}

.testimonial-data-items :nth-child(1){
    width: 30px;
    height: 30px;
}
.testimonial-data-items :nth-child(2){
    font-size: 13px;
    font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-style: normal; 
      text-decoration: none;
      margin-top: 20px;
      
      
}
.testimonial-data-items :nth-child(3){
    font-size: 16px;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-style: normal; 
      color: #AD88C6;
      text-decoration: none;
}
.testimonial-data-items :nth-child(4){
    font-size: 12px;
}

@media (max-width:800px){
    .testimonial-data {
        width: 100%;
        height: auto;
        justify-content: space-evenly;
        /* align-items: center; */
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        /* background-color: yellowgreen; */
        gap: 20px;
        padding-top: 100px;
    }
    
}