.Governance-pic{
        width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-image: url('https://t4.ftcdn.net/jpg/05/30/15/55/240_F_530155555_gW281CXfigFFKB2oes3aCIr242zJUTg6.jpg'); */
    background-image: url('../../images/jl8.jpg');
    background-attachment: fixed; 
    object-fit: cover;
    background-size: cover;
    background-position: center;
}