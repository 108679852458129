.swiper-img1 {
  width: 100%;
  height: 700px;
  position: relative;
}
.swiper-content1-one {
  width: 100% !important;
  height: 700px;
  background-color: rgb(0, 0, 0);
  /* overflow: hidden; */
  background: no-repeat url("../images/jl16.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: end;
  /* object-fit: contain; */
}
.swiper-content1-one-one {
  height: 500px;
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-right: 90px;
  /* marg */
  margin-top: 190px;
  /* background-color: brown; */
}

.swi-span1 {
  color: white;
  font-size: 29px;
  font-weight: 600;
}
.swi-span2 {
  color: rgb(219, 215, 215);
  margin-top: 20px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
    /* font-weight: 500; */
    font-style: normal;
}
.swiper-content1-two {
  width: 100% !important;
  height: 700px;
  background-color: rgb(0, 0, 0);

  background: no-repeat url("../images/jl17.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: end;
  /* object-fit: contain; */
}
.swiper-content1-three {
  width: 100% !important;
  height: 700px;
  background-color: rgb(0, 0, 0);

  background: no-repeat url("../images/jl18.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: end;
  /* object-fit: contain; */
}

.swiper-content1-four {
  width: 100% !important;
  height: 700px;
  background-color: rgb(0, 0, 0);

  background: no-repeat
    url("https://images.unsplash.com/reserve/bOvf94dPRxWu0u3QsPjF_tree.jpg?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: start;
  /* object-fit: contain; */
}
.swiper-content1-one-one-side {
  margin-left: 50px;
}
.swiper-content1-four-one {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: rgba(0, 0, 0, 0.351);
}

.akhilll {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: rgba(0, 0, 0, 0.351);
}

.swiper-content1-five {
  width: 100% !important;
  height: 700px;
  background-color: rgb(0, 0, 0);

  background: no-repeat url("../images/jl19.jpg");

  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: end;
  /* object-fit: contain; */
}

.dna-one {
  width: 100%;
  height: 700px;
  
  background-color: rgba(0, 0, 0, 0.687);
  background: fixed
    url(../images/dna.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}


.card-main {
  width: 69.5%;
  /* height: 700px; */
  /* background-color: aquamarine; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 30px;
  margin-bottom: 50px;
  /* margin: 30px 0px; */
}
.card-short1 {
  width: 300px;
  height: 250px;
  background-color: white;
  display: flex;
 
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  padding: 15px 15px;
  border-radius: 15px;
  border: 1px solid white;
}
.card-shot-img1 {
  width: 90px;
  height: 90px;
}
.card-shot-span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  font-style: normal;
  text-decoration: none;
}
.card-shot-span1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  /* margin-top: 20px; */
  color: rgb(109, 108, 108);

}
.card-shot-read {
  margin-top: 12px;
}
.card-short1:hover {
  border: 1px solid #7469B6;
}
.card-head-shot {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
}
.card-head-shotq {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 0px;
}

.kill {
  height: auto  !important;
  width: auto !important;
  /* background-color: #000000; */
  padding: 0px !important;
  /* background: none; */
  border: none !important;
  margin-top: 50px;
  /* border-radius: 50%; */

  /* justify-content: center; */

  /* transform: skewX(45deg); */
}
.kill {
  animation: pulse 2s infinite;
}

.dna-icon-link-align{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #156c5f; */
  flex-direction: column;

}
.kill img{
  width: 80px;
  height: 80px;
}
@keyframes pulse {
  0%,
  100% {
    transform: skewX(0deg) scale(0.2);
  }
  50% {
    transform: skew(2deg) scale(0.8);
  }
}
.kill:hover {
  animation: none;
}
.dna-heading{
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: white;
    top: 280px;
    left: 230px;
    max-width: 500px;
    position: absolute;
}
.dna-graet{
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: white;
    top: 280px;
    left: 730px;
    max-width: 600px;
    position: absolute;
}

.kil11 {
  display: flex;
  width: 100%;
  height: 100%;

}
.dna-background{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.635);   
}

.kil11 {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.557); */
}

.kil11 > div {
  position: absolute;
  width: 90px;
  height: 90px;

  /* background-color: rgb(137, 17, 17);  */

}

/* Define random positions for each grid item */
.kil11 > div:nth-child(1) {
  top: 10%;
  left: 5%;

}
.dna-zoom{position: absolute;

    /* top: 500px; */
}
.kil11 > div:nth-child(2) {
  top: 50%;
  left: 5%;
  display: flex;
  align-items: center;
 
}
.kil11 > div:nth-child(3) {
  top: 67%;
  left: 25%;


}
.kil11 > div:nth-child(4) {
  top: 50%;
  left: 49.3%;

}
.kil11 > div:nth-child(5) {
  top: 12%;
  left: 43%;

}
.kil11 > div:nth-child(6) {
  top: 60%;
  left: 70%;

}
.kil11 > div:nth-child(7) {
  top: 12%;
  left: 60%;
  
}
.kil11 > div:nth-child(8) {
  top: 15%;
  left: 83%;

}
.kil11 > div:nth-child(9) {
  top: 50%;
  left: 84%;
  /* display: none; */
}

.kill span {
  color: white;
  display: none;
  /* text-align: center; */
  /* max-width: 150px; */
  /* width: 150px; */
  text-align: center;
  /* background-color: #156c5f; */
  /* white-space: nowrap; */
}

.kill:hover.kill span {
  display: block;
  transition: calc(1s);
  animation: none;
  
  /* width: 150px; */
  /* background-color: #156c5f; */
  /* white-space: ; */
}

.care-main-cqc {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl11.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
  background-color: #ee8961;
}
.about-opacity {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}
.about-texts {
  width: 50%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-texts p {
  color: white;
}
.cqc-consul {
  color: white;
}
.care-content-container {
  width: 800px;
  height: 500px;
  /* background-color: #156C5F; */
  position: absolute;
  margin-top: 200px;
  
}
.care-content-container span {
  color: white;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  /* background-color: aqua; */
}
.care-content-container p {
  color: white;
  font-size: 24px;
  /* line-height: 30px; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* font-style: normal; */

}
.card-single3{
  width: 500px;
  height: auto;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}
.card-single3 span{
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #AD88C6;
  /* margin-top: 20px; */
}

.care-menu-one1 {
  width: auto;
  height: auto;
  padding: 20px;
  background-color: #7469B6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  /* margin-bottom: 30px; */
  /* margin-left: 20px; */
}
.care-menu-one1-inspection {
  width: auto;
  height: auto;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  box-shadow: 0px 2px 4px grey;
  /* margin-left: 20px; */
}
.care-menu-one1 span {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  cursor: pointer;
}
.care-need-help {
  color: white;
  font-size: 16px !important;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;

}
.care-need-help-font{
  font-size: 18.5px !important;
  color: rgb(94, 92, 92) !important;
  font-family: "Poppins", sans-serif;
}
.boot-button6-main{
  background-color: #cbcbcb;
  color: white;
}
.cqre-quality-span{
  background-color: #ebebeb76;
  padding: 8px 0px;
  
}
.care-menu-main-container {
  width: 68%;

  margin: auto;

    display: flex;
    flex-wrap: wrap;
  gap: 10px;
  
  /* background-color: aqua; */
  justify-content: center;
  /* margin-bottom: 80px; */
}
.care-menu-one1:hover {
  background-color: #AD88C6;
}
.care-menu-think {
  width: 70%;
  /* background-color: #EE8961; */
  text-align: center;
  margin: 10px 0px 30px 0px;
  font-size: 15px;
}
.case-main-content {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl12.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
.cheriya {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: #156C5F; */
  justify-content: center;
  margin-top: 50px;
}
.cheriya span {
  /* background-color: #EE8961; */
  width: auto;
}
.cheriya p {
  text-align: center;
}

.league-main-content {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl13.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
.case-study-main-content {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl14.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
.cases-single-card {
  height: 310px;
  width: 230px;
  display: flex;
  flex-direction: column;
  border-bottom: 0.2px solid #ee8961;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 5px;

  /* background-color: #156C5F; */
}
.cases-single-card-image {
  width: 230px;
  height: 180px;
}
.cases-single-card :nth-child(2) {
  width: 90%;
  color: grey;
  font-family: "Poppins", sans-serif;
  /* font-weight: 500; */
  font-style: normal;
  font-size: 15px;
}
.case-sudies-sspam {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #ee8961;
  font-size: 14px;
}
.cases-mian {
  display: flex;
  width: 80%;
  /* background-color: #196E62; */
  margin: auto;
  gap: 30px;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}
.contacts-main-c11 {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl15.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
.insights-main {
  width: 100%;
  height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/jl20.jpg");
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
}

.cases-single-card-insight {
  height: auto;

  width: 320px;
  display: flex;
  flex-direction: column;
  border-bottom: 0.2px solid #ee8961;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 10px;
  gap: 8px;

  /* background-color: #156C5F; */
}
.cases-single-card-image-insight {
  width: 100%;
  height: 300px;
}
.cases-single-card-insight :nth-child(3) {
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  font-style: normal;
  text-align: justify;
}
.cases-mian-insight {
  display: flex;
  width: 80%;
  /* background-color: #196E62; */
  margin: auto;
  gap: 30px;
  margin-bottom: 30px;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 1490px) {
  .card-main {
    /* display: none; */
    width: 85.9%;
    display: flex;
    flex-wrap: wrap;
   /* background-color: aqua; */
    justify-content: center;
  }
  
  /* .card-short1{
    width: 20%;
    height: 230px;
  } */
}

@media (max-width: 1150px) {
  .card-main {
    /* display: none; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   /* background-color: aqua; */
    justify-content: center;
  }
   .care-menu-main-container {
        width: 98%;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
  /* .card-short1{
    width: 20%;
    height: 230px;
  } */
}





@media (max-width:800px) {

    .card-main {
        /* display: none; */
        width: 100%;
        display: flex;
        flex-wrap: wrap;
       /* background-color: rgb(0, 0, 0); */
        justify-content: center;
      }
      .dna-one{
        /* display: none; */
      }
      /* .card-short1{
        width: 80%;
      } */

     
    
}
@media (max-width: 1480px) {
    .care-menu-main-container {
        width: 78%;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
}
@media (max-width: 480px) {
    .care-menu-main-container {
        width: 100%;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
      .swiper-content1-one-one {
        height: 500px;
        width: 500px;
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        /* marg */
        margin-top: 10px;
        /* background-color: brown; */
      }
      .swiper-content1-one-one{
        margin-left: 10px;
      }
}
@media (max-width: 1400px) {
    .care-menu-main-container {
        width: 85%;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
}

@media (max-width: 1300px) {
    .care-menu-main-container {
        width: 90%;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
}
@media (max-width: 1200px) {
    .care-menu-main-container {
        width: 98%;
       justify-content: center;
        /* background-color: #196e62; */
        /* gap: 50px; */
      }
      .kil11 > div:nth-child(6) {
        top: 65%;
        left: 65%;
      
      }
      .kil11 > div:nth-child(9) {
        top: 69%;
        left: 80%;
        /* display: none; */
      }
      .dna-heading{
        font-size: 40px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
        color: white;
        top: 260px;
        left: 30px;
        max-width: 500px;
        position: absolute;
      }
      .dna-graet{
        font-size: 40px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
        color: white;
        top: 260px;
        left: 530px;
        max-width: 600px;
        position: absolute;
      }
}






@media (max-width:1070px) {
  .dna-one{
    /* display: none; */
  }

  .dna-background{
    display: flex;
    width: 100%;
    height: 100%;
    /* background-color: rgba(228, 10, 10, 0.635);    */
}

.dna-graet{
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
  top: 260px;
  left: 530px;
  max-width: 500px;
  position: absolute;
}
.dna-heading{
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
  top: 260px;
  left: 30px;
  max-width: 450px;
  position: absolute;
}
.kil11 > div:nth-child(2) {
  top: 65%;
  left: 5%;
 
}
}

@media (max-width:970px) {
  .dna-graet{
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: white;
    top: 260px;
    left: 470px;
    max-width: 500px;
    position: absolute;
  }
  .dna-heading{
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: white;
    top: 260px;
    left: 10px;
    max-width: 400px;
    position: absolute;
  }
}

@media (max-width:790px) {
  .dna-one{
    /* display: none; */
  }

  .dna-background{
    display: flex;
    width: 100%;
    height: 100%;
    /* background-color: rgba(228, 10, 10, 0.635);    */
}

.dna-graet{
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: white;
  top: 180px;
  left: 370px;
  max-width: 600px;
  position: absolute;
}
.dna-heading{
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: white;
  top: 120px;
  left: 10px;
  max-width: 300px;
  position: absolute;
}
.dna-one{
  height: 400px;
}

.kil11 > div:nth-child(2) {
  top: 57%;
  left: 5%;
 
}
.kil11 > div:nth-child(1) {
  top: 2%;
  left: 5%;
 
}
.kil11 > div:nth-child(3) {
  top: 60%;
  left: 25%;

}
.kil11 > div:nth-child(4) {
  top: 65%;
  left: 49.3%;

}
.kil11 > div:nth-child(5) {
  top: 14%;
  left: 43%;

}
.kil11 > div:nth-child(6) {
  top: 65%;
  left: 65%;

}
.kil11 > div:nth-child(7) {
  top: 15%;
  left: 60%;
  
}
.kil11 > div:nth-child(8) {
  top: 15%;
  left: 80%;

}
.kil11 > div:nth-child(9) {
  top: 59%;
  left: 80%;
  /* display: none; */
}

}
@media (max-width:578px) {

  .dna-one{
    /* display: none;   */
    height: 500px;
  }


  .dna-graet{
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    top: 320px;
    left: 10px;
    max-width: 300px;
    position: absolute;
  }
  .dna-heading{
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    top: 120px;
    left: 10px;
    max-width: 300px;
    position: absolute;
  }

  .kil11 > div:nth-child(2) {
    top: 75%;
    left: 0%;
   
  }
  .kil11 > div:nth-child(1) {
    top: 10%;
    left: 81%;
   
  }
  .kil11 > div:nth-child(3) {
    top: 75%;
    left: 24%;
  
  }
  .kil11 > div:nth-child(4) {
    top: 75%;
    left: 52%;
  
  }
  .kil11 > div:nth-child(5) {
    top: 37%;
    left: 81%;
  
  }
  .kil11 > div:nth-child(6) {
    top: 75%;
    left: 77%;
  
  }
  .kil11 > div:nth-child(7) {
    top: 25%;
    left: 81%;
    
  }
  .kil11 > div:nth-child(8) {
    top: 48%;
    left: 80%;
  
  }
  .kil11 > div:nth-child(9) {
    top: 61%;
    left: 81%;
    /* display: none; */
  }
  .akhilll{
    padding-left: 15px;
    /* background-color: #ee8961; */
  }
  .care-menu-think{
    text-align: start;
    width: 90%;
  }
  .swiper-content1-four-one {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: rgba(0, 0, 0, 0.758);
}
.akhilll {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: rgba(0, 0, 0, 0.351);
}
.akhilll {
  width: 100%;

  height: 700px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: rgba(0, 0, 0, 0.751);
}

  @keyframes pulse {
    0%,
    100% {
      transform: skewX(0deg) scale(1);
    }
    50% {
      transform: skew(2deg) scale(0.5);
    }
  }
  .card-main {
    margin-bottom: 5px;
    padding: 2px;
    /* background-color: #196e62; */
  }

  .kill img{
  
  
  width: 40px;
  height: 40px;

  }

}
