.nav-main {
  width: 100%;
  height: 100px;
  /* background-color: #b1b3b39a; */
  display: flex;

  align-items: center;
  justify-content: space-around;
  position: absolute;
  z-index: 993;
  /* position: sticky; */
  /* top: 50px; */
  /* position: fixed; */
  /* top: 0; */

  width: 100%;

  /* background-color: yellowgreen; */

  /* position: fixed; */
  transition: background-color 0.3s ease;
}
.nav-main.scrolled {
  top: 0px;
  position: fixed;
  background-color: #ad88c6;
}

.num1 {
  height: 70px;
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 8px grey;
  position: fixed;
  z-index: 2;
  top: 0;
  /* margin-bottom: 500px; */
  /* bottom: 00px; */
}
.num11 {
  width: 50%;
  /* background-color: rgb(233, 230, 230); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.img1 {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.num12 {
  width: 300px;
  /* background-color: rgb(196, 55, 55); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 50px;
}
.home {
  font-size: 14px;
  cursor: pointer;
}
.zaballo {
  font-size: 35px;
  font-weight: 500;
  color: #3e4772;
}
.za {
  color: red;
}
.home1 {
  height: auto;
  width: auto;
  background-color: rgb(255, 255, 255);
  /* margin-top: 15px; */

  position: absolute;
  display: none;
}
.home11:hover .home1 {
  display: block;

  z-index: 2;
}
.home11 {
  position: relative;
  display: inline-block;
}
.home2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto !important;
  margin-left: 15px;
  padding: 20px 0px;
  gap: 10px;
  width: 250px;
  /* margin-top: 10px; */
}

.home28 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 130px;
  margin-left: 15px;
  margin-top: 10px;
}
.home11:hover .home18 {
  display: block;

  z-index: 2;
}
/* .kil{
  display: none;
  position: absolute;
  top: 40px;
  width: 300px;
  height: 50px;
  background-color: aqua;
} */
/* .ki{
  position: relative;
  display: inline-block;
}
.ki:active .kil{
  display: block;
} */
.likey {
  width: 300px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  z-index: 5;
  /* margin-top: 100px; */
  margin-left: 1200px;
  margin-top: -10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: fixed;
  border: 1px solid rgb(189, 183, 183);
}
.kill {
  width: 250px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid rgb(208, 206, 206);
  /* padding: 30px; */
  padding-left: 20px;
}
.kill::placeholder {
  /* margin-left: 200px;\
   */
  /* color: #3E4772; */
  /* padding-left: 10px; */
}
.kill:focus {
  border-color: 1px solid grey;
}

.jiji {
  text-decoration: none;
  color: rgb(70, 70, 70);
}
.jiji:hover {
  color: black;
}
.nav-span-link2 {
  width: 230px;
  padding: 8px 0px;
  height: auto;
  padding-left: 10px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  /* border-bottom: 2px solid #0f6155; */
}
.nav-span-link2:hover {
  background-color: rgb(239, 239, 239);
}
.nav-sub-at {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #7469b6;
  justify-content: space-between;
  /* padding-right: 20px; */
}
.gilll1 {
  /* margin-left: 150px; */
}
.gill {
  width: 20px !important;
  height: 20px !important;
}
.litiha {
  margin-left: 50px;
}
.nav-sub-at-one {
  display: flex;
  align-items: center;
  gap: 7px;
  width: 270px;
  /* background-color: #3E4772; */
}
.nav-sub-at-one span {
  font-size: 17px;
  color: white;
}
.nav-sub-at-one-span {
  color: white;
  font-size: 14px;
  font-family: "poppins", sans-serif;
}
.nav-icon {
  width: 60px;
  height: 60px;
}
.nav-span {
  font-size: 18px;
  max-width: 200px;
  font-weight: 500;
  color: white;
}
.nav-sub {
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-sub1 {
  height: 100px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.nav-link-decoration {
  text-decoration: none;
}
.nav-sub-span {
  color: white;
  font-size: 17px;
}
.nav-call-img {
  width: 40px;
  height: 40px;
  background-color: #ee8961;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.nav-call {
  display: flex;
  gap: 10px;
}
.nav-call-main1 {
  display: flex;
  flex-direction: column;
}
.nav-call-main1 span {
  color: white;
}
.call-us {
  font-size: 13px;
}
.call-no {
  font-size: 20px;
  font-weight: 600;
}
.main-img-container {
  width: 100%;
  height: 800px;
  /* position: absolute; */
  background-color: black;
  background: url(./images/three.jpg);
  background-size: cover;
  display: flex;

  /* bottom: 10%; */
}

.main-img {
  width: 100%;
  height: 100%;
  background-color: #397e74dd;
  display: flex;
}
.main-cnt {
  height: 500px;
  width: 80px;
  /* background-color: #EE8961; */
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.main-rnd {
  height: 45px;
  width: 45px;
  /* background-color: #196E62; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-rnd:hover {
  /* height: 45px;
  width: 45px; */

  background-color: #ad88c6;
  transform: translateY(-5px);
  transition: calc(0.5s);
}
.main-face {
  width: 25px;
  height: 25px;
}
.main-cnt-2 {
  height: 600px;
  width: 600px;
  /* background-color: #EE8961; */
  margin-top: 250px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}
.main2-soltn {
  color: white;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 19px;
}
.main2-soltn1 {
  font-size: 58px;
  color: white;
}
.main2-soltn2 {
  color: white;
  font-size: 20px;
  /* margin-top: 200px; */
}

.wrapper {
  /* width: 150px;
  height: 150px; */
  position: fixed;
  top: 50%;
  left: 50%;
  /* transform: translateX(-50%) translateY(-50%); */
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 0, 0, 0.2);
  border-radius: 30px;
}

.item {
  width: 200px;
  height: 50px;
  background: white;
  transform-origin: 50% 100%;
}
.item-1-1 {
  width: 200px;
  height: 50px;
  /* background-color: #EE8961; */
}
.main-kia-1 {
  height: 3px;
  /* margin: 2px solid white; */
  background-color: white;
  width: 50px;
}
.main2-btn {
  width: 400px;
  height: 80px;
  /* background-color:aqua; */
  margin-top: 30px;
}
.look {
  /* margin-top: 200px; */
  display: flex;
  gap: 10px;
  margin-top: 40px;
  /* margin-left: 50px; */
}
.main3-cards {
  width: 610px;
  height: 700px;
  /* background-color: aqua; */
  margin-top: 100px;
  display: flex;
  margin-left: 179px;
}
.main-card-side1 {
  display: flex;
  flex-direction: column;
  /* background-color: #196E62; */
  gap: 22px;
  margin-top: 100px;
}
.main-card-side1-1 {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 20px;
  margin-left: 200px;
}
.main-card-img1 {
  width: 250px;
  height: 250px;
  margin-top: 70px;
  margin-left: 20px;
}
.main-card-img2 {
  width: 300px;
  height: 300px;
  margin-top: 20px;
  margin-left: 25px;
}

.main-card-side1-2 {
  background-color: #ee8961;
  width: 80px;
  height: 80px;
  /* background-color: white; */
  border-radius: 20px;
}
.look-container {
  width: 390px;
  height: 100px;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 100px;
}
.kbtn {
  width: 200px;
  height: 55px;
  background-color: cadetblue;
  outline: none;
  border: none;
  font-size: 18px;
  color: white;
  border-radius: 5px;
  background-color: #ee8961;
  font-weight: 600;
}
.kbtn1 {
  width: 180px;
  color: rgb(57, 56, 56);
  background-color: white;
}
.mnv {
  width: 200px;
  height: 50px;
  background-color: black;
}

.main-card-side-1-1-1 {
  width: 280px;
  height: 280px;
}

.mnb {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  /* background-color: blue; */
  /* position: absolute; */
  /* top: 800px; */
}
.kkkkkk {
  position: static;
}
/* .sununu{
  height: 500px;
  width: 100%;
  background-color: #EE8961;
  position: absolute;
} */
.menu1-span {
  color: #ad88c6;
  font-size: 27px;
  font-family: monospace;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 20px;
}
.last-six-cases {
  background-color: #ad88c6;
}
.menu1-span-1 {
  font-size: 38px;
  font-weight: 500;
  max-width: 850px;
  font-family: monospace;
  text-align: center;
  margin-top: 2px;

  /* background-color: #156C5F; */
}
.menu1-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  /* background-color: #3E4772; */
}

.join-our-team1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 70px;
  width: 70%;
  margin: auto;
  margin: 30px auto;
}

.menu-carss {
  width: 84% !important;
  height: 1100px;
  /* padding: 20px; */
  gap: 30px;
  /* margin: auto; */
  /* background-color: #196E62; */
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
}
.menu-cars-1 {
  width: 30%;
  height: 440px;
  /* background-color: #EE8961; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}
.mennu-cars-img1 {
  width: 90%;
  height: 240px;
  border-radius: 30px;
  /* width: 340px;
   height: 240px;
   border-radius: 30px; */
}
.mennu-cimage {
  position: absolute;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  top: 190px;
  left: 40% !important;
  border-radius: 50px;
  background-color: #ad88c6;
}
.menu-care {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #ad88c6;
  margin-top: 40px;
  max-width: 100%;
}
.menu-care1 {
  max-width: 100%;
  text-align: center;
  margin-top: 15px;
  color: rgb(161, 159, 159);
  /* background-color: #0f6155; */
}
.menu-read {
  margin-top: 15px;
  font-size: 14px;
  color: #7469b6 !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}
.menu-read-link {
  text-decoration: none;
  /* background-color: aquamarine; */
}
.mennu-cimage1 {
  left: 520px;
}
.mennu-cimage2 {
  left: 920px;
}
.kkkkkk {
  position: static;
}
/* .sununu{
  height: 500px;
  width: 100%;
  background-color: #EE8961;
  position: absolute;
} */
/* .menu1-span{
  color: #EE8961;
  font-size: 17px;
  font-family: monospace;
  
  font-weight: 700;
} */
.menu1-span-1 {
  font-size: 38px;
  font-weight: 500;
  max-width: 850px;
  font-family: monospace;
  text-align: center;
  margin-top: 30px;
  /* background-color: #156C5F; */
}

.join-our-team1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 70px;
  width: 70%;
  margin: auto;
  margin: 30px auto;
}

.menu-carss {
  /* width: 1100px; */
  height: auto;
  margin: auto;
  /* background-color: #196E62; */
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
}
.menu-cars-1 {
  width: 30%;
  height: auto;
  /* background-color: #EE8961; */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
}

.mennu-cimage {
  position: absolute;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  top: 190px;
  left: 120px;
  border-radius: 50px;
  background-color: #ad88c6;
}
/* .menu-care{
  font-size: px;
  font-family: monospace;
  margin-top: 40px; 
} */
.menu-care1 {
  max-width: 80%;
  text-align: center;
  margin-top: 15px;
  color: rgb(161, 159, 159);
}
.menu-read {
  margin-top: 15px;
  font-size: 14px;
  color: #156c5f;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}
.mennu-cimage1 {
  left: 520px;
}
.mennu-cimage2 {
  left: 920px;
}
.ab-main {
  height: 450px;
  width: 80%;
  margin: auto;
  /* background-color: #156C5F; */
  display: flex;
  margin-top: 60px;
}
.ab-menu {
  display: flex;
  flex-direction: column;
}
.ab-menu {
  margin-left: 25px;
}
.ab1 {
  color: #ee8961;
  font-size: 18px;
  margin-top: 20px;
}
.ab2 {
  font-family: monospace;
  font-size: 35px;
  color: black;
  max-width: 500px;
  margin-top: 20px;
}

.ab3 {
  font-size: 20px;
  max-width: 690px;
  color: rgb(157, 154, 154);
  font-family: monospace;
  margin-top: 20px;
}
.ab4 {
  display: flex;
  flex-wrap: wrap;
  width: 650px;
  /* background-color: aqua; */
  justify-content: space-between;
  /* gap: 50px; */
  height: 100px;
  margin-top: 30px;
}
.ab5 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  font-family: monospace;
}
.ab6 {
  width: 200px;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  background-color: #156c5f;
  color: white;
}
.ab7 {
  margin-right: 70px;
}
.cb-main {
  width: 67%;
  height: 480px;
  margin: auto;
  /* background-color: #37b2b2; */
  display: flex;
  /* margin-top: 70px; */
  margin-bottom: 100px;
}
.cb2 {
  width: 65%;
  height: 100%;
  /* background-color: #832727; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-left: 190px; */
}

.cb-side-card {
  height: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #6a61ee; */
}
.cb-side-card-1 {
  height: 150px;
  width: 330px;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 8px rgb(183, 182, 182);
  display: flex;
  padding-left: 10px;
  align-items: center;
  border-radius: 10px;
  justify-content: space-evenly;
  gap: 20px;
}
.cb-side-images {
  width: 50px;
  height: 50px;
}
.cb-side-card-subdiv {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 250px;
  /* background-color: aquamarine; */
}
.cb-side-card-subdiv :nth-child(1) {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
}
.cb-side-card-subdiv :nth-child(2) {
  /* color: rgb(120, 119, 119); */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  max-width: 210px;
  /* word-break: break-all; */
  word-wrap: break-word;
}

.cb4-one {
  color: #ad88c6;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.cb1 {
  width: 50%;
}
.cb3 {
  color: grey;
  font-size: 19px;
  font-family: monospace;
  margin-top: 80px;
}
.cb4 {
  font-size: 37px;
  font-family: monospace;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.cb5 {
  font-size: 14px;
  color: rgb(153, 152, 152);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;

  margin-top: 10px;
}
.cb7 {
  display: flex;
  align-items: center;
  gap: 125px;
  height: 55px;
  border: 1px solid rgb(215, 213, 213);
  width: 48%;
  border-radius: 10px;
  padding-left: 20px;
}
.cb7221 {
  display: flex;
  align-items: center;
  gap: 125px;
  height: 150px;
  border: 1px solid rgb(215, 213, 213);
  width: 98%;
  border-radius: 10px;
  /* padding-left: 20px; */
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
}

.C {
  background: none;
  border: none;
  outline: none;
  color: rgb(154, 152, 152);
  /* width: 70%; */
  /* height: 100%; */
}
.cb82221 {
  background: none;
  border: none;
  outline: none;
  color: rgb(164, 162, 162);
  height: 100%;
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: start;
}
.cb82221::placeholder {
  color: rgb(163, 159, 159);
}

.cb8::placeholder {
  color: rgb(173, 170, 170);
}
.cb9 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 12px;
  margin-top: 30px;
}
.cb10 {
  width: 98%;
  height: 60px;
  border: none;
  border-radius: 12px;
  font-size: 17px;
  background-color: #7469b6;
  font-weight: 600;
  margin-top: 30px;
  color: white;
}
.cb8 {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.cb10:hover {
  color: white;
  background-color: #ad88c6;
  transition: calc(0.5s);
}
input[type="date"] {
  /* padding: 2px; */
}
::-webkit-calendar-picker-indicator {
  /* background-color: aqua; */
  color: white;
  display: none;

  border-color: white;
}

.mi1 {
  width: 90%;
  height: 467px;
  background-color: #ee8961;
  margin: auto;
  display: flex;
  margin-top: 70px;
}
.mi2 {
  width: 45%;
}
.mi3 {
  display: flex;
  flex-direction: column;
}
.mi4 {
  margin-top: 10px;
}
.mi44 {
  font-size: 24px;
}

.ft-main {
  height: 300px;
  width: 100%;
  background-color: #ad88c6;
}
.ft1 {
  height: 170px;
  left: 200px;
  width: 76%;
  border-radius: 20px;
  margin: auto;
  background-color: #ee8961;
  display: flex;
  /* justify-content: space-evenly; */
  gap: 15px;
  padding-right: 20px;
  /* position: absolute; */
  /* position: relative; */
  /* bottom: 200px; */
  position: absolute;
  align-items: center;
}
.ft1 div {
  max-width: 350px;
  margin-left: 20px;
}
.ft1 span {
  font-size: 38px;
  font-weight: 400;
  font-family: monospace;
  max-width: 150px;
  color: white;
  /* background-color: #156C5F; */

  /* height: 400px; */
  /* overflow: hidden; */
  /* word-wrap: break-word; or word-break: break-all; */
}
.ft1 input {
  width: 500px;
  border: none;
  background-color: #ef916c;
  height: 56px;
  outline: none;
  font-size: 15px;
  color: white;
  /* background-co */
  border: 0.1px solid rgb(214, 211, 211);
  border-radius: 10px;
  padding-left: 20px;
}
.ft1 input::placeholder {
  color: white;
}
.ft1 button {
  width: 200px;
  background-color: white;
  color: black;
  border: none;
  height: 58px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
}
.ft2 {
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  /* display: inline; */
  display: flex;

  /* margin-top: 100px; */
}
.ft3 {
  height: 300px;
  width: 100%;
  background-color: #7469b6;
  /* margin-top: 100px; */
  display: flex;
}
.ft4 {
  /* margin-top: 50px; */
  /* background-color: #EE8961; */
  height: 200px;
  width: 300px;
  display: flex;
  align-items: center;
  margin-left: 200px;
  margin-top: 50px;
  gap: 10px;
}
.ft6 {
  width: 50px;
  height: 50px;
}
.ft4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ft5 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.ft5 span {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: monospace;
}
.ft7 {
  color: white;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
}
.ft7:hover {
  color: white;
}
.ft-babin {
  width: 100%;
  height: 80px;
  background-color: #7469b6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.1px solid rgb(181, 181, 181);
}
.ft-babin span {
  color: white;
  font-size: 14px;
}

.ft8 {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.ft9 {
  background-color: #6a5cba;
}
.ft10 {
  width: 20px;
  height: 20px;
}
.ft21 {
  width: 300px;
  height: 200px;
  /* background-color: #EE8961; */
  margin-top: 50px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ft22 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: monospace;
}
.ft23 {
  display: flex;
  align-items: center;
  margin-top: 4px;
  /* background-color: #196E62; */
  justify-content: center;
}
.ser-content {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffffd4;
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.ser-content p {
  width: 80%;
  margin: auto;
  color: rgb(130, 126, 126);
  font-size: 17px;
  /* font-family: monospace; */
}
.semain {
  background-color: #0a0a0a6f;
  height: 400px;
}
.semain1 {
  height: 400px;
}
.ilavayi {
  margin-top: 200px;
  margin-left: 400px;
}
.chalich {
  margin-top: 10px;
}
.premium {
  margin-left: 100px;
}
.babin-aparathm {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.babin-gundan {
  font-size: 40px;
  font-weight: 750;
  /* margin: auto; */
  margin-top: 50px;
}
.babin-atti {
  max-width: 680px;
  line-height: 28px;
  font-size: 17px;
  color: grey;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}
.babin-jazi {
  color: #156c5f;
}

.nav-medaiq-icon {
  display: none;
}

.get-btn {
  font-weight: 500;
}
.cursor {
  cursor: pointer;
}
.nav-data:hover {
  border-bottom: 2px solid black;
}

.offcanvas-div {
  width: 100%;
  /* position: absolute; */
  height: 100%;
  /* top: 0; */
  background-color: rgb(255, 255, 255);
  z-index: 999;
  /* right: 0; */
  overflow-y: scroll;
  /* position: sticky; */
}

.issubmainmain {
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 999;
  /* position: absolute; */
  right: 0;
  width: 90%;
  overflow-y: scroll;
  /* background-color: rgb(137, 7, 7); */
}

.issubmain {
  height: auto;

  /* height: 100%; */
  /* position: absolute; */
  /* margin-top: 90px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 999;
  /* position: fixed; */
  top: 0px;
}
.dropdown-content1 {
  /* display: none; */
  /* position: absolute; */
  /* background-color: #f9f9f9; */
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition-duration: calc(2s);
}
.cnt-btn-q {
  padding: 10px;
  font-size: 25px;
  border: none;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: start;
  flex-direction: column;
  /* color: white; */
}

.dropdown-content1 a {
  color: rgb(0, 0, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 19px;
  transition-duration: calc(2s);
  text-align: start;
}

@media (max-width: 1700px) {
  .menu-carss {
    width: 85%;
    /* height: 1100px; */
    /* padding: 20px; */
    gap: 30px;
    /* margin: auto; */
    /* background-color: #196E62; */
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media (max-width: 1550px) {
  .menu-carss {
    width: 100%;
    /* height: 1100px; */
    /* padding: 20px; */
    gap: 30px;
    /* margin: auto; */
    /* background-color: #196E62; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .nav-sub-at {
    display: none;
  }
  .nav-main {
    /* display: none; */
  }
  .menu1-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    /* background-color: #3E4772; */
  }
  .cb10 {
    width: 90%;
  }
  .cb-main {
    width: 80%;
    height: 550px;
    margin: auto;
    /* background-color: #37b2b2; */
    display: flex;
    /* margin-top: 70px; */
    margin-bottom: 100px;
  }
}

@media (max-width: 1050px) {
  .nav-sub1 {
    display: none;
  }
  .ft-main {
    /* display: none; */
  }
  .nav-medaiq-icon {
    display: block;
    margin-right: 7px;
    /* width: 150px; */
    /* height: 25px; */
    /* background-color: #196E62; */
  }

  .ft1 {
    left: 100px;
    padding-right: 20px;
  }
  .ft4 {
    margin-top: 0px;
    /* background-color: #EE8961; */
    height: 220px;
    width: 300px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 40px;
    gap: 10px;
  }
  .nav-main {
    justify-content: space-between;
  }
  .ft1 span {
    font-size: 25px;
    font-weight: 400;
    font-family: monospace;
    max-width: 150px;
    color: white;
    /* background-color: #156C5F; */

    /* height: 400px; */
    /* overflow: hidden; */
    /* word-wrap: break-word; or word-break: break-all; */
  }
  .ft1 input {
    width: 70%;
    border: none;
    background-color: #ef916c;
    height: 56px;
    outline: none;
    font-size: 15px;
    color: white;
    /* background-co */
    border: 0.1px solid rgb(214, 211, 211);
    border-radius: 10px;
    padding-left: 20px;
  }

  .ft1 button {
    width: 30%;
    background-color: white;
    color: black;
    border: none;
    height: 58px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .cb-main {
    width: 100%;
    height: 550px;
    margin: auto;
    /* background-color: #37b2b2; */
    display: flex;
    /* margin-top: 70px; */
    margin-bottom: 100px;
  }

  .nav-icon {
    /* width: 40px;
  height: 40px; */
    margin-left: 8px;
  }
}
@media (max-width: 790px) {
  .ft7 {
    color: white;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;

    /* background-color: #EE8961; */
  }
  .ft2 {
    height: auto;
  }
  .ft3 {
    height: auto;
    width: 100%;

    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
  }
  .ft21 {
    /* background-color: #3E4772; */
    margin-top: 20px;
    margin-left: 50px;
    height: auto;
    padding-bottom: 25px;
  }
  .ft4 {
    margin-top: 100px;
    margin-left: 50px;
    margin-bottom: 30px;
  }
  .foot-margin-top {
    margin-top: 100px;
  }
  .cb-main {
    width: 100%;
    height: auto;
    margin: auto;
    /* background-color: #37b2b2; */
    display: flex;
    flex-direction: column;
    /* margin-top: 70px; */
    margin-bottom: 100px;
  }
  .cb-side-card-1 {
    height: 150px;
    width: 90%;
    margin: auto;
    background-color: #fdfdfd;
    box-shadow: 0px 4px 8px rgb(183, 182, 182);
    display: flex;
    /* padding-left: 0px; */
    align-items: center;
    border-radius: 10px;
    justify-content: start;
    gap: 20px;
  }
  .cb-side-card {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    /* background-color: #EE8961; */
  }
  .cb2 {
    width: 99%;
    height: 100%;
    /* background-color: #832727; */
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 35px;
    /* padding-left: 190px; */
  }

  @media (max-width: 700px) {
    .foot-margin-top {
      margin-top: 0px;
    }
  }
}

@media (max-width: 578px) {
  .ft1 {
    left: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px 20px 0px 0px;
    padding: 15px 0px;
  }
  .ft1 input {
    width: 60%;
    margin-left: 15px;
  }
  .menu1-span {
    color: #ad88c6;
    font-size: 27px;
    font-family: monospace;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .nav-span {
    font-size: 17px;
  }
  .nav-icon {
    width: 50px;
    height: 50px;
  }
  .join-our-team1 {
    width: 90%;
  }
  .menu1-main {
    align-items: center;
    width: 93%;
    margin: auto;
  }
  .cb-main {
    width: 90%;

    padding: 0px;
  }
  .cb7 {
    padding-left: 1px !important;
    /* background-color: #3E4772; */
  }
  .cb9 {
    display: flex;
    flex-wrap: wrap;
    /* gap: 5px 5px; */
    margin-top: 30px;
  }
  .cb10 {
    width: 96%;
    /* background-color: #0f6155 !important; */
  }
  .cb-side-card-1 {
    height: 150px;
    width: 97%;
    margin: auto;
    background-color: #fdfdfd;
    box-shadow: 0px 4px 8px rgb(183, 182, 182);
    display: flex;
    padding-left: 1px;
    align-items: center;
    border-radius: 10px;
    justify-content: start;
    gap: 10px;
    /* background-color: #3E4772; */
  }
  .menu1-span-1 {
    font-size: 38px;
    font-weight: 500;
    max-width: 850px;
    font-family: monospace;
    text-align: center;
    margin-top: 5px;

    /* background-color: #156C5F; */
  }
}
